<template>
  <div class="course-container">
    <h3 style="text-align: left;">课程内容列表</h3>
    <div style="flex: 1;height: 100%;padding-bottom: 15px;box-sizing: border-box;overflow-y: auto">
      <div v-for="(song,index) in songs" class="song-item" style="position: relative;" :key="index">
        <p class="song-name"> {{ song.position + " " + song.song_name }}</p>
        <p class="song-intro">{{ song.song_name_sub }}</p>
        <div style="width: 100%;margin-top: 10px ;display: flex;flex-direction: row;align-items: center;">
          <img src="../../../assets/time_icon.png" style="width: 15px;height: 15px;object-fit: cover">
          <p :id="'audio_duration'+ index" class="audio-duration">
            {{ formatSeconds(song.song_audio_address_length) }}
          </p>
          <p class="song_name_abbrev" style="margin-left: 15px">让孩子学会{{ song.song_name_abbrev }}</p>
        </div>
      </div>
    </div>
    <div class="btnGOIn" @click="gotoSong">立即进入</div>
  </div>
</template>

<script>
import {checkEnableInCourse, getCourseInfo} from '../../../api/keneng'

export default {
  name: "CourseInfo",
  data() {
    return {
      course_id: this.$route.query.id,
      songs: [],
      user_id:''
    }
  },
  mounted() {
    if (false) {
      //测试支付
      this.course_id = '64ec4d7ea4640b4b4c8b459f'
    }
    this.fetchData()
  },
  methods: {
    gotoSong() {
      checkEnableInCourse(this.course_id).then((res) => {
        let result = res.data
        if (result == 0) {
          // this.$router.push({
          //   path: '/quiz',
          //   query: {
          //     course_id: this.course_id
          //   }
          // })
          this.$router.push({
            path: '/audio',
            query: {
              course_id: this.course_id
            }
          })
        } else if (result == -1) {
          this.$message.error('请先完成上一个课程的学习')
        } else if (result == -2) {
          this.$message.error('免费课程已经结束，请订阅后可继续学习')
          this.gotoPay()
        }
      })
    },
    gotoPay() {
      this.$router.push({
        path: '/subscribe',
        query: {
          user_id: this.user_id
        }
      })
    },
    fetchData() {
      getCourseInfo(this.course_id).then((res) => {
        let temp = res.data.songs
        this.user_id = res.data.user_id
        for (let i = 0; i < temp.length; i++) {
          let item = temp[i]
          var position = i + 1
          if (position < 10) {
            position = "0" + position
          }
          item['position'] = position
          this.songs.push(item)
        }
      })
    },
    formatSeconds(seconds) {
      let min = 0
      let sec = seconds
      if (seconds >= 60) {
        min = parseInt(seconds / 60)
        sec = parseInt(seconds - min * 60)
      }
      let str_min = min
      if (min < 10) {
        str_min = '0' + min
      }
      let str_sec = sec
      if (sec < 10) {
        str_sec = '0' + sec
      }
      return str_min + ':' + str_sec
    },
  },
}
</script>

<style scoped lang="less">

.btnGOIn {
  text-align: center;
  width: 100%;
  cursor: pointer;
  height: 42px;
  line-height: 42px;
  font-size: 18px;
  font-family: PingFang SC-Semibold, PingFang SC;
  font-weight: 600;
  color: #FFFFFF;
  background: #5D5BB5;
  box-shadow: 0px 0px 24px 1px rgba(0, 0, 0, 0.05);
  border-radius: 26px 26px 26px 26px;
}

.course-container {
  background-color: white;
  width: 100%;
  height: 100vh;
  position: relative;
  overflow-y: auto;
  padding: 10px 20px 20px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.audio-duration {
  margin-left: 6px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #666666;
}

.song_name_abbrev {
  padding: 3px 4px;
  box-sizing: border-box;
  background: #DEE1FF;
  border-radius: 4px;
  font-size: 8px;
  font-family: Alibaba PuHuiTi 2.0-65 Medium, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #5D5BB5;
  line-height: 14px;
}

.song-item {
  margin-bottom: 20px;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 10px 15px;
  box-sizing: border-box;
  background: #FFFFFF;
  box-shadow: 0px 3px 15px 1px rgba(93, 91, 181, 0.16);
  border-radius: 6px;
  background: #FFFFFF;
  cursor: pointer;
}

.song-name {
  font-size: 14px;
  font-family: Alibaba PuHuiTi 2.0-75 SemiBold, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #5D5BB5;
}

.song-intro {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  margin-top: 12px;
  font-size: 10px;
  font-family: Alibaba PuHuiTi 2.0-55 Regular, Alibaba PuHuiTi 20;
  font-weight: normal;
  color: #999999;
  line-height: 18px;
}
</style>
